import { RealtimeChannel } from "@supabase/supabase-js";

import { useCurrentOrganization } from "~/business-areas/organization/composables/current-organization.hook";

export const useCurrentOrganizationWalletStore = createGlobalState(() => {
  let realtimeChannel: RealtimeChannel | null = null;

  const { organization } = useCurrentOrganization();
  const client = useDatabaseClient();

  const { data, refresh } = useAsyncData(
    async () => {
      if (organization.value?.id === undefined) return null;

      const response = await client
        .from("organization_view_wallets")
        .select()
        .eq("organization_id", organization.value.id)
        .returns<{ balance: number }[]>()
        .single();

      return response.data;
    },
    {
      watch: [() => organization.value?.id],
    },
  );

  watch(
    () => organization.value?.id,
    async () => {
      if (realtimeChannel) await client.removeChannel(realtimeChannel);

      if (organization.value?.id === undefined) return;

      realtimeChannel = client
        .channel("organization_credit_packs_update")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "organization_credit_packs",
            filter: `organization_id=eq.${organization.value.id}`,
          },
          () => refresh(),
        )
        .subscribe();
    },
    { immediate: true },
  );

  return { organizationWallet: readonly(data), refresh };
});
